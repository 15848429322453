export const diplome = [{
    id:"diplome-6", date: "2024-2025", lieu:"OC",
    diplome: "Titre professionnel Niv7",
    description: "Datascientist, Machine Learning Engineer",
    text: "Techniques de statistique, apprentissage automatique et visualisation de données. Conception et developpement des modèles d'apprentissage automatique tout en assurant leur déploiement et leur maintenance."
},
{id:"diplome-5",date: "2022-2024", lieu:"OC",
    diplome: "Titre professionnel Niv6",
    description: "Concepteur, Developpeur de Logiciels :  Reactjs",
    text:"Elaboration d'architecture et de conception d'applications. Création, programmation, déploiement, test, documentation et maintenance de logiciels avec la spécialisation ReactJs"
},
{id:"diplome-4",date: "2020-2022", lieu:"UPHF",
    diplome: "Master",
    description: "Humanités Numériques :  User eXperience, Communications Numériques et Objets Connectés",
    text:"Interaction entre les technologies numériques et les disciplines des sciences humaines. Modélisation de base de données, statistique et algorithme avec une spécialisation User Experience (UX), conception de systèmes numériques autour de l'expérience utilisateur en prenant en compte son comportement, son objectif et ses besoins"
},
{id:"diplome-3",date: "2018-2020", lieu:"UPHF",
    diplome: "Master",
    description: "Arts et Management",
    text: "Connaissance des arts, de la culture et des institutions culturelles. Gestion de projets, droit, finance et marketing axés sur les évènement culturels. "
},
{id:"diplome-2",date: "2015-2018", lieu:"UPHF",
    diplome: "Licence",
    description: "Arts, Design Graphique et Design d'interaction",
    text:"Creation visuelle et conception interactive. Couvre le développement d'identités visuelles, supports de communication, conception d'experience numériques interactives. Connaissance accrue de l'histoire de l'art et du design."
},
{id:"diplome-1",date: "2014-2015", lieu:"UPHF",
    diplome: "DAEU",
    description: "A - Litterature",
    text:"Spécialité Literature, avec l'option économie"
}
];


export const experience = [,
    {id:"experience-3",date: "2024", role:"AutoEntrepreneur",
    description: "Linkleen : Programmation, Design, Conception" ,
    text:"Lancement de mon activité"
    },
    {id:"experience-2",date: "2019", role:"Bénévole",
    description: "Sytrileen: Management, Programmation, Design, Conception",
    text:"Soutien aux artistes, accompagnement, conseils, mentorat, mon rôle est principalement numérique"
    }, {id:"experience-1",date: "2008", role:"Artiste Numérique",
description: "Art, Musique, vidéo, 3D, Programmation, Conception",
    text:"partage, autonomie, initiative, autodidacte, patience, créativité, audace, résilience"
}
]

export const hardSkill = [
{id: "groupe2", hardSkill: ["Javascript", "Typescript", "NodeJs"]},
{id: "groupe1", hardSkill: ["Html/Css"]},
{id: "groupe10", hardSkill: ["UX Design", "Interaction", "Design Motion", "Design Responsive"]},
{id: "groupe8", hardSkill: ["Reactjs", "ReactNative", "Angular", "NextJs", "ThreeJs", "Express", "Django"]},
{id: "groupe3", hardSkill: ["Algorithme", "Python", "C#"]},
{id: "groupe4", hardSkill: ["Mysql", "Postgresql", "Firestore", "MongoDb"]},
{id: "groupe5", hardSkill: ["Adobe Suite", "Microsoft Office Suite", "VsCode", "JetBrain Suite"]},
{id: "groupe6", hardSkill: ["Docker", "Kubernetes", "Git", "GitHub", "GitLab", "Npm Package"]},
{id: "groupe7", hardSkill: ["Google Cloud Platfom", "Google Cloud Run", "Artifact Registry", "Firebase", "Authentication"]},
{id: "groupe9", hardSkill: ["Jest","Pytest","Documentation Technique","Jira","Trello"]}
]

export const competence = [
    {id:"competence-1",
    description: "*Conception graphique et interaction",
    text:"Conception d'interfaces : administratives, applications internes, sites web, applications mobiles, en veillant à une cohérence visuelle et fonctionnelle pour chaque environnement et objectif."
    },
    {id:"competence-2",
    description: "*Gestion de projets",
    text:"Expérience des projets en situation professionnelle simulée : De l'évènement culturel jusqu'aux applications. J'utilise principalement Jira avec Confluence"
    },
    {id:"competence-3",
    description: "*Programmation en Javascript, Python, C#",
    text:"JS pour le web, Python avec Flask et Django, C# appliqué à Unity et UiPath"
    },
    {id:"competence-4",
    description: "*Développement d'interfaces avec React",
    text:"Ainsi qu'avec NextJs et Angular selon le projet"
    },
    {id:"competence-5",
    description: "*Bases de données, API",
    text:"Modèlisation de données, interface APIrests, ORM, je travaille avec Mysql, Postgresql, MongoDb"
    },
    {id:"competence-6",
    description: "*Visualisation de données",
    text:"Bibliothèque D3 pour Js, Matplotlib, Seaborn, Plotly, Pandas et d'autres avec Python"
    },
    {id:"competence-7",
    description: "*Techniques de machine learning",
    text: "MlOps, statistique, modélisation de données et l'usage de principaux concepts de Machine Learning: étiquetage, reconnaissance d'image, NLP etc"
    },
];


export const softSkill = [{id: "softGroupe1", hardSkill: ["Esprit", "d’équipe"]},
{id: "softGroupe2", hardSkill: ["Veille", "Technologique"]},
{id: "softGroupe3", hardSkill: ["Sens", "du", "partage"]},
{id: "softGroupe4", hardSkill: ["Curiosité"]}
];

export const passion = [{id: "passionGroupe1", hardSkill: ["Musique"]},
{id: "passionGroupe2", hardSkill: ["Electronique"]},
{id: "passionGroupe3", hardSkill: ["Retrogame"]},
{id: "passionGroupe3", hardSkill: ["Ingenierie"]},
{id: "passionGroupe4", hardSkill: ["Peinture"]}
];
