import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedMenu: "",
    selectedArtMenu: ""
}

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        menuSelected: (state, action) => {
            state.selectedMenu = action.payload;
        },
        menuArtSelected: (state, action) => {
            state.selectedArtMenu = action.payload;
        }
    }
});

export const { menuSelected, menuArtSelected } = menuSlice.actions;

export default menuSlice.reducer;
