import memoize from 'lodash/memoize';

export const shuffleArray = memoize(array => {
    let currentIndex = array?.length, randomIndex;
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
});

export const sortArrayByName = memoize(array => array.slice().sort((a, b) => a.title.localeCompare(b.title)));
export const sortArrayByDate = memoize(array => array.slice().sort((a, b) => new Date(a.date) - new Date(b.date)));
