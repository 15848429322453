import Logo from "../components/menuprincipal/Logo";
import Menu from "../components/menuprincipal/Menu";
import Rgpd from "../components/rgpd/Rgpd";
function HomePage(){
    return <>
    <Logo/>
    <Menu/>
    <Rgpd/>
   </>
}

export default HomePage;
