import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";


const specialiteStyle = styled.div`
   height:fit-content;
   width:100%;
   display:flex;
   padding-bottom:200px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   top:200px;
   overflow-y:hidden;
   position:absolute;
   @media (min-width: 200px) and (max-width: 1200px) {
    top:300px;
}
`;

const SpanMark = styled.span`
    font-weight: 700;
    font-size: larger;


`;

const TexteStyle = styled.div`
    height:fit-content;
    width: 70%;
    right:0;
    bottom:0;
    margin: 5px auto;
    border-radius: 25px;
    padding:15px;
    box-sizing: border-box;
    background-color: ${variablesGlobals.opacityWhite};
    color: ${variablesGlobals.darkGreyBack};
    font-family: ${variablesGlobals.fontFamilyWriting};
    position:relative;
    h1{
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8em;
    }
    @media (min-width:200px) and (max-width:1200px){
        width:100%;
        font-size: small;

    }
`;



// Variants pour gérer l'animation en cascade
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4, // Délai entre chaque enfant
      },
    },
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 60 },
    visible: { opacity: 1, y: 0 },
  };

  
function specialite(){
    return(
        <specialiteStyle variants={containerVariants}
        initial="hidden"
        animate="visible"><TexteStyle variants={itemVariants}>
            <h1>Partage de ma spécialité</h1></TexteStyle><TexteStyle>
            <p>
            <p>Je propose des cours et démonstrations autour de mes spécialités : le dessin, le design, l'UX design, et le developpement front-end</p> 
            </p></TexteStyle><TexteStyle variants={itemVariants}> <p>
            Par mes activités, le travail et le temps, j'ai acquis une expertise en l'intégralité de la suite Adobe.
            Je présente mes premiers cours pour :
           
            </p></TexteStyle> <TexteStyle variants={itemVariants}><p>
            Adobe Illustrator: apprenez à réaliser vos illustrations, vos logos en format SVG efficacement et rapidement, je vous partage des astuces, des conseils, et je vous accompagne lors des démonstrations
           
            </p></TexteStyle> <TexteStyle variants={itemVariants}><p>
            Adobe Photoshop: Tirez profit de toute la puissance de Photoshop pour vos oeuvres, mais aussi de la nouvelle intégration de son IA génératrice d'image
           
            </p></TexteStyle> <TexteStyle variants={itemVariants}><p>
            Adobe Animate: Animez vos logo, vos illustrations pour un design motion plus qu'exemplaire!
            
            </p></TexteStyle><TexteStyle variants={itemVariants}><p>
            Adobe inDesign: mettez en page vos documents, flyer, affiches, soyez créatif, ne perdez plus votre temps : utilisez des raccourcis!
            
            </p></TexteStyle><TexteStyle variants={itemVariants}><p>
            Adobe XD: Imaginez votre site pour le concretiser : relation interactive entre fenetres, aperçu responsive, gestion des composants et plus encore!


           </p></TexteStyle>
           
           <TexteStyle><p>
            </p></TexteStyle><TexteStyle><p>
        </p><p>               
            </p></TexteStyle>
        </specialiteStyle>
    )
}

export default specialite;