
import { createGlobalStyle } from 'styled-components';
import { variablesGlobals } from './variables';

export const GlobalStyled = createGlobalStyle`
    body {
        margin:0;
        padding:0;
        font-size: 20px;
        font-family: ${variablesGlobals.fontFamilyLogo};
        background-color: ${variablesGlobals.lightGreyBack};
        max-width: 100vw;
        min-width: 200px;
        max-height: 100vh;
        overflow: hidden;
    }
    `;