import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";
import { Link } from "react-router-dom";
import CollapseMenu from "./CollapseMenu";
import {menuArtList} from "../artcomponents/menuArtList";
import CollapseMenuBottom from "./MenuBottom";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Search from "../recherchecomponents/Search";

const MainContainer = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    top:0;
    left:0;
`
const LogoStyle = styled(Link)`
    top:0;
    display:flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
    position:relative;
    width:fit-content;
    margin:20px;
    color: ${variablesGlobals.darkGreyBack};
    font-family: ${variablesGlobals.fontFamilyLogo};
    font-size: 1.5em;
    transition:0.5s;
    z-index: 10000;
    text-decoration: none;
    @media (min-width: 200px) and (max-width: 1200px) {
        left:0;
        font-size: 1em;

    }
    &:hover{
        color:white;
        transition:0.5s;
    }
`
const LogoLinkLeenWrapp = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`

const LogoLink = styled.span`
    font-size: 0.5em;
    border:none;
    font-weight: 400;
    `
const LogoLeen = styled.span`
    margin:0;
    font-size: 2.5em;
    font-weight: 400;
    line-height: 0.95em;
    `

const LogoCom = styled.span`
    font-size: 0.5em;
    font-weight: 400;
    line-height: 2.3em;

    `

function Navigation(){
    // const menuSelectedArtBottom = useSelector(state => state.menuReducer.selectedArtMenu);
    const location = useLocation();
    return(
        <MainContainer>
            <LogoStyle to="/">
                <LogoLinkLeenWrapp><LogoLink>Link </LogoLink>
                <LogoLeen>Leen</LogoLeen>
                </LogoLinkLeenWrapp>
                <LogoCom>.com</LogoCom>
            </LogoStyle>
            <CollapseMenu/>
            {location.pathname.includes("/art") &&
            <>
            {/* <CollapseMenuBottom menuListBottom = {menuArtList} menuType = "MENU_ART_SELECTED" typeSelected={menuSelectedArtBottom} link="art"/> */}
            </>}{
            (location.pathname.includes("/art/visuel") || location.pathname.includes("/art")) && <Search/>
            }
        </MainContainer>
    )
}

export default Navigation;