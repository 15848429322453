import styled from "styled-components";
import { variablesGlobals } from "../../style/variables"; 
import { Link } from "react-router-dom";
import {menuList} from "./menuList";
import {formatMenu} from "../utils/utils";
import { useDispatch } from "react-redux";
import { menuSelected } from "../../reduxhandler/menuredux/menuSlice";

const ButtonWrapper = styled.div`
    height: 100vh;
    opacity: 0;
    z-index: 200;
    margin-left: -50px;
    position: absolute;
    bottom:10%;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    transition: 0.5s;
    @media (min-width: 200px) and (max-width: 1200px) {
        margin-left:0;
        opacity:1;
    }
    &:hover{
        margin-left:0;
        transition: 0.5s;
        opacity: 1;
    }
`;

const Button = styled(Link)`
    color: ${variablesGlobals.darkGreyBack};
    background-color: white;
    border: none;
    height:fit-content;
    width:fit-content;
    text-align: left;
    padding: 30px 150px 30px 20px;
    margin: 20px 0 0 0;
    font-size: 1.0em;
    z-index: 17;
    clip-path: polygon(0% 0%, 100% 0%,80% 100%, 0% 100%);
    transition: 0.5s;
    border-radius: 0 200px 50px 0;
    text-decoration: none;
    cursor: pointer;
    @media (min-width: 200px) and (max-width: 1200px) {
        font-size:smaller;
        margin:5px;
        padding:5px 50px;
    }
    &:hover{
        font-size: 2em;
        color:white;
        background-color: ${variablesGlobals.darkGreyBack};
        transition: 0.5s;
        @media (min-width: 200px) and (max-width: 1200px) {
        font-size:medium;
    }
    }
`;

function Menu() {
    const dispatch = useDispatch();
  
    const handleClickMenu = (elementMenu) => {
      dispatch(menuSelected(elementMenu));
    };
  
    return (
      <>
        <ButtonWrapper>
          {menuList.map((elementMenu, i) => (
            <Button
              to={formatMenu(elementMenu)} key={i + elementMenu}
              onClick={() => handleClickMenu(elementMenu)}
            >
              {elementMenu}
            </Button>
          ))}
        </ButtonWrapper>
      </>
    );
  }
  

export default Menu;