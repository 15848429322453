import BarLight from "../../assets/icones/bars-solid white.svg";
import BarDark from "../../assets/icones/bars-solid.svg";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { menuList } from "../menuprincipal/menuList";
import { formatMenu } from "../utils/utils";
import { Link } from "react-router-dom";
import { variablesGlobals } from "../../style/variables";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuArtSelected, menuSelected } from "../../reduxhandler/menuredux/menuSlice";

const MainContainer = styled.div`
    position:absolute;

    z-index: 200;
    height: fit-content;
    width: fit-content;


    @media (min-width: 200px) and (max-width: 1200px) {
        background-color: #ffffff67;
        border-radius: 15px;
    }


`
const CollapseButton = styled.button`
    position: relative;
    z-index: 20;
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    background-color: transparent;
    border: none;
    margin: 5px 20px;
    padding: 0;
    
    cursor: pointer;
    & img{
        width: 50px;
        height: 50px;
    }
`
const MenuElementSelected = styled.div`
    color: ${variablesGlobals.darkGreyBack};
    background-color: white;
    clip-path: polygon(0% 0%, 100% 0%,80% 100%, 0% 100%);
    border-radius: 0 200px 50px 0;
    height:fit-content;
    font-size: 1.5em;
    width:100%;
    font-family: ${variablesGlobals.fontFamilyLogo};
    padding: 10px 70px 10px 10px;
    margin: 5px 0;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 1em;
      padding:10px 50px;
    }
    
`

const MenuListWrapper = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left:20px;
    `

const MenuElement = styled(Link)`
    text-decoration: none;
    background-color: #ffffff;
    margin: 5px 0;



    color: ${variablesGlobals.darkGreyBack};
    clip-path: polygon(0% 0%, 100% 0%,80% 100%, 0% 100%);
    border-radius: 0 200px 50px 0;
    transition: 0.5s;
    padding:10px 100px 10px 10px;
    font-size: 1em;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.5em;
    }
    &:hover{
        background-color: ${variablesGlobals.darkGreyBack};
        color: white;
        transition: 0.5s;
        font-size: 1.2em;
    }
`

function CollapseMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath = location.pathname;
    const menuSelectedRedux = useSelector(state => state.menuReducer.selectedMenu);
  
    useEffect(() => {
      const matchedMenu = menuList.find(menu => "/" + formatMenu(menu) === currentPath);
      if (matchedMenu) {
        dispatch(menuSelected(matchedMenu));
      }
    }, [currentPath]);

    
    const handleClickMenu = (elementMenu) => {
      dispatch(menuSelected(elementMenu));
    };
    
    const [menuClicked, setMenuClicked] = useState(false);
  
    const toggleMenu = (e) => {
      setMenuClicked(!menuClicked);
      e.stopPropagation();
    };
  
    return (
      <MainContainer>
        <CollapseButton onClick={toggleMenu}>
          <MenuElementSelected>{menuSelectedRedux}</MenuElementSelected>
          {!menuClicked
            ? <img src={BarDark} alt="menu" />
            : <img src={BarLight} alt="menu" />}
        </CollapseButton>
        {menuClicked && (
          <MenuListWrapper onClick={toggleMenu}>
            {menuList.map((elementMenu, i) => (menuSelectedRedux !== elementMenu &&
              <MenuElement to={formatMenu(elementMenu)} key={i + elementMenu}
                onClick={() => handleClickMenu(elementMenu)}
              >
                {elementMenu}
              </MenuElement>
            ))}
          </MenuListWrapper>
        )}
      </MainContainer>
    );
  }
  
  
  export default CollapseMenu;
  