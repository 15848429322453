export function filterListByKeywords(list, keywords, selectedTags) {
    const normalizedKeywords = keywords.map(kw => kw.toLowerCase());
    const normalizedTags = selectedTags.map(tag => tag.toLowerCase());

    // Filtrer d'abord les objets qui contiennent tous les tags sélectionnés
    const filteredByTags = list.filter(obj =>
        normalizedTags.every(tag => 
            obj.tags.map(objTag => objTag.toLowerCase()).includes(tag)
        )
    );

    // Ensuite, filtrer ces résultats pour inclure ceux qui correspondent à au moins un mot-clé
    const finalFilteredResults = filteredByTags.filter(obj =>
        normalizedKeywords?.length === 0 || normalizedKeywords.some(keyword =>
            obj.title.toLowerCase().includes(keyword) ||
            obj.author.toLowerCase().includes(keyword) ||
            obj.description.toLowerCase().includes(keyword) ||
            obj.date.includes(keyword) ||
            obj.technic.toLowerCase().includes(keyword) ||
            obj.category.toLowerCase().includes(keyword) ||
            obj.text.toLowerCase().includes(keyword)
        )
    );

    return finalFilteredResults;
}
