import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";
import InfosBulle from "./InfosBulle";

const OngletContainer = styled.div`
   
`;

const OngletTitle = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    box-sizing: border-box;
    font-weight: 700;

    padding:1px 15px;
    flex-wrap: nowrap;
    cursor: pointer;
`;


function OngletCompetence({ competence }) {
    const { id= "", description = "", text="" } = competence || {};

    return (
        <>
            {competence && (
                <InfosBulle tooltipText={text}><OngletContainer>
                    <OngletTitle>
                        <p>{description}</p>
                    </OngletTitle>
                </OngletContainer></InfosBulle>
            )}
        </>
    );
}

export default OngletCompetence;