import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    displayModal: false,
    dataSelected: null,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggleModalDisplay: state => {
            state.displayModal = !state.displayModal;
        },
        setModalData: (state, action) => {
            state.dataSelected = action.payload;
        },
    },
});

export const { toggleModalDisplay, setModalData } = modalSlice.actions;

export default modalSlice.reducer;
