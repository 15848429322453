import styled from "styled-components";
import { variablesGlobals } from "../../../style/variables";

const TagsSelectedComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2px 10px;
    width: 100%;
    margin:0;
    padding:0;

`;

const TagsSelectedButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: ${variablesGlobals.darkGreyBack};
    margin: 0px 5px;
    color: #ffffff;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.9em;
    font-weight: 400;
    transition: 0.5s;
    cursor: pointer;
    @media (min-width: 200px) and (max-width: 1200px) {
        font-size: 0.5em;
    }
    &:hover{
        background-color: white;
        color: ${variablesGlobals.darkGreyBack};
        transition: 0.5s;
        font-size:0.6em;
    }
`;


function TagsSelected({ tags, deleteTag }) {

    return (
        <TagsSelectedComponent>
            {tags.map(tag => (
                <TagsSelectedButton onMouseDown={()=> deleteTag(tag)} 
                key={tag}>{tag}</TagsSelectedButton>
            ))}
        </TagsSelectedComponent>
    );
}

export default TagsSelected;