
import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";
import InfosBulle from "./InfosBulle";




const OngletContainer = styled.div`
 
`;

const OngletTitle = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    box-sizing: border-box;
    padding:1px 40px;
    flex-wrap: nowrap;
    cursor: pointer;
    div{

    }
    p{
        width:fit-content;
        white-space: nowrap;

    }
`;
const OngletDescription = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    box-sizing: border-box;
    padding:3px 10px;
`;
function OngletDiplomes({diplomePart}){
    const {id="", date="", lieu="", diplome="", description="", text=""} = diplomePart || {};

    return <>{diplome
    &&    <InfosBulle tooltipText={text}>
    <OngletContainer>
    <OngletTitle>{date} {lieu}</OngletTitle>
    <OngletDescription>{diplome} {description}</OngletDescription></OngletContainer></InfosBulle>
    }
   
    </>
}

export default OngletDiplomes;
