
import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";




const OngletContainer = styled.div`
    min-width:fit-content;
    width:170px;
    height:fit-content;
    display:flex;
    flex-direction: column;
    border-radius: 15px;
    text-align:center;
    overflow: hidden;
    background:linear-gradient(to top, white, ${variablesGlobals.lightGreyBack});
    font-size:0.65em;
    transition: filter 0.5s ease-in-out;
    cursor:pointer;
    margin:5px auto;
    color: ${variablesGlobals.veryDarkGreyBack};
    &:hover{
        filter:brightness(120%);
    }
`;

const OngletTitle = styled.div`
    display: flex;
    flex-direction: row;
    width:fit-content;
    box-sizing: border-box;
    padding:1px 40px;
    flex-wrap: nowrap;
    cursor: pointer;
    div{

    }
    p{
        width:fit-content;
        white-space: nowrap;

    }
`;
function OngletPassion({passion}){
    const {hardSkill=[]} = passion || {};

    return <>{passion
    &&<OngletContainer>
    <OngletTitle>{hardSkill.map((element, i)=> <p key={`p-${i}`}>{element}</p>)}</OngletTitle>
    </OngletContainer>
    }
   
    </>
}

export default OngletPassion;
