import * as THREE from 'three';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';
import { formatUrlMiniature } from '../../components/utils/utils';

const textureCache = new Map();  // Cache to store loaded textures

async function loadTexture(urlImage) {
    const textureLoader = new THREE.TextureLoader();
    return new Promise((resolve, reject) => {
        textureLoader.load(urlImage, texture => {
            texture.anisotropy = textureLoader.maxAnisotropy; // Enhance texture quality
            resolve(texture);
        }, undefined, reject);
    });
}

async function createImage3D(data, position) {
    try {
        const urlImage = formatUrlMiniature(data);

        // Check cache first
        let texture = textureCache.get(urlImage);
        if (!texture) {  // If not in cache, load and store it
            texture = await loadTexture(urlImage);
            textureCache.set(urlImage, texture);
        }

        const imageWidth = 2000;
        const ratioOriginal = data.dimensionVirtuel.dX / data.dimensionVirtuel.dY;
        const imageHeight = imageWidth / ratioOriginal;

        // Setting dimensions for the mesh
        const dX = imageWidth * 0.001;
        const dY = imageHeight * 0.001;
        const dZ = 0.10;
        const radius = 0.01;  // The radius for the rounded corners
        const smoothness = 10;  // How smooth the corners should be

        // Create geometry, material, and mesh
        const geometry = new RoundedBoxGeometry(dX, dY, dZ, smoothness, radius);
        const material = new THREE.MeshStandardMaterial({ map: texture });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(position.x, position.y, position.z);

        return mesh;
    } catch (error) {
        console.error("Erreur lors du chargement de la texture: ", error);
        throw error;  // Enhance error handling
    }
}

export default createImage3D;
