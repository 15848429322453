import { sortByDate, sortByName, sortRandomly } from '../../reduxhandler/object3dredux/object3dSlice';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { useDispatch } from 'react-redux';

const ArrayOrderChoice = styled.div`
    position: flex;
    width:fit-content;
    justify-content: flex-end;
    z-index: 1000;
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: 0.8em;
    flex-direction: row;
    gap: 2px;
    margin-top: 5px;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.6;

    }
`

const ButtonOrderChoice = styled.button`
    color:white;
    background-color: transparent;
    width: fit-content;
    border:${variablesGlobals.lightGreyBack} 2px solid;
    font-family: ${variablesGlobals.fontFamilyLogo};
    margin:auto 5px;
    border-radius: 20px;
    text-align: center;
    color: ${variablesGlobals.darkGreyBack};
    cursor: pointer;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.8em;

    }
    &:hover{
      background-color: ${variablesGlobals.lightGreyBack};
      color: white;
      transition: 0.5s;
      border: 2px solid ${variablesGlobals.darkGreyBack};
    }
`

function ArrayDisplay() {
  const dispatch = useDispatch();
  return (
    <ArrayOrderChoice>classer par: 
      <ButtonOrderChoice onClick={() => dispatch(sortByName())}> Nom</ButtonOrderChoice>
      <ButtonOrderChoice onClick={() => dispatch(sortByDate())}>Date</ButtonOrderChoice>
      <ButtonOrderChoice onClick={() => dispatch(sortRandomly())}>Hasard</ButtonOrderChoice>
    </ArrayOrderChoice>  
  );
}

export default ArrayDisplay;
