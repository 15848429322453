import './App.css';
import GetRoutes from './getroutes/GetRoutes';
import { Provider } from 'react-redux';
import store from './store/store';
import SceneRef from './3d/utils3d/Scene3d';
import styled from 'styled-components';
import { GlobalStyled } from './style/GlobalStyled';
import Footer from './components/menuprincipal/Footer';
const SceneThree = styled(SceneRef)`
width: 100vw;  
height: 100vh;
position:absolute;
top:0;
left:0;
z-index:1;`;
function App() {

  return (
  <Provider store = {store}>            <GlobalStyled />

      <SceneThree>
      <GetRoutes/>
      </SceneThree>
      <Footer/>
  </Provider>
  );
}

export default App;
