import BarLight from "../../assets/icones/bars-solid white.svg";
import BarDark from "../../assets/icones/bars-solid.svg";
import styled from "styled-components";
import { useState } from "react";
import { formatMenu } from "../utils/utils";
import { Link } from "react-router-dom";
import { variablesGlobals } from "../../style/variables";
import { useDispatch } from "react-redux";
import { menuArtSelected } from "../../reduxhandler/menuredux/menuSlice";

const MainContainer = styled.div`
    position: absolute; 
    bottom: 5%;
    height: fit-content;
    width: fit-content; 
    z-index: 1000;
    margin-left:10px;

`;






const CollapseButton = styled.button`
    position: relative;
    z-index: 20;
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    flex-direction: row;
    background-color: transparent;
    border: none;
    width: 100%; 
    padding: 10px; 
    cursor: pointer;
    & img {
        width: 50px;
        height: 50px;
    }
`;

const MenuElementSelected = styled.div`
    color: ${variablesGlobals.darkGreyBack};
    background-color: white;
    clip-path: polygon(0% 0%, 80% 0%,100% 100%, 0% 100%);
    border-radius: 0 50px 200px 0;
    height:30px;
    font-size: 1.5em;
    font-family: ${variablesGlobals.fontFamilyLogo};`;

const MenuListWrapper = styled.div`
    display:flex;
    position: relative;
    top:50%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left:10px;
    `;

const MenuElement = styled(Link)`
    background-color: #ffffff;
    margin: 5px 0;
    color: ${variablesGlobals.darkGreyBack};
    clip-path: polygon(0% 0%, 80% 0%,100% 100%, 0% 100%);
    border-radius: 0 50px 200px 0;
    transition: 0.5s;
    padding:10px 100px 10px 10px;
    font-size: 1em;`;


function CollapseMenuBottom({menuListBottom, menuType, typeSelected, link}){
    const dispatch= useDispatch();
    const handleClickMenu = (elementMenu) => {
        if (menuType === "MENU_ART_SELECTED"){
        dispatch(menuArtSelected(elementMenu));
        }
    }
    
    const [menuClicked, setMenuClicked] = useState(false);
    const toggleMenu = () => {
        setMenuClicked(!menuClicked);
    }

    return(
        <MainContainer>  
            
            {menuClicked && (
                <MenuListWrapper onMouseLeave={toggleMenu}>
                    {menuListBottom.map((elementMenu, i) => ( typeSelected !== elementMenu &&
                        <MenuElement to={link+"/"+formatMenu(elementMenu)} key={i+elementMenu}
                        onClick = {() => handleClickMenu(elementMenu)}
                        >
                            {elementMenu}
                        </MenuElement>
                    ))}
                </MenuListWrapper>
            )}
            <CollapseButton onMouseEnter={toggleMenu}>
                {!menuClicked
                ?<img src={BarDark} alt="menu" />
                :<img src={BarLight} alt="menu" />}
                
            {typeSelected&&<MenuElementSelected>{ typeSelected }</MenuElementSelected>}
            </CollapseButton>
        </MainContainer>
    )

}

export default CollapseMenuBottom;