// import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Tags from "./Tags";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import { variablesGlobals } from "../../../style/variables";
import { setTags } from "../../../reduxhandler/tagsredux/tagsSlice";


const TagButtonContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height:fit-content;
`;

const TagContain = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    display:${props => props.$isVisible ? 'flex' : 'none'};
    justify-content: flex-end;
    align-items: flex-start;
    width: 70%;
    position: relative;
    top: -40px;
    right: 0;
    height:60%;
    margin-right:0;
    @media (min-width: 200px) and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-end;
        width:fit-content;
        flex-wrap: wrap;
        top:30px;
    }
    `


const Button = styled.button`  
    position: relative;
    background-color: white;
    border: none;
    top: -30px;
    cursor: pointer;
    color: ${variablesGlobals.darkGreyBack};
    font-size: 1em;
    width:fit-content;
    padding: 10px;
    border-radius: 0 0 0 20px;
    z-index: 100;
    transition: 0.5s;
    @media (min-width: 200px) and (max-width: 1200px) {
        top: -10px;
        position:absolute;
        font-size: 0.6em;
        &:hover{
        font-size: 0.7em;
        background-color: white;
        transition: 0.5s;
    }
    }
    &:hover{
        font-size: 0.9em;
        background-color: white;
        transition: 0.5s;
    }
`;
function TagsSection({ wordKey }) {
    const [isVisible, setIsVisible] = useState(false);
    const data = useSelector((state) => state.object3dReducer.filteredList) || [];
    const dispatch = useDispatch();

    useEffect(() => {
        const allTags = new Set();
        data.forEach(obj => {
            obj.tags.forEach(tag => { allTags.add(tag) });
        });

        dispatch(setTags(Array.from(allTags)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const tags = useSelector((state) => state.tagsReducer.tagsList);


    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    }
    const blockClick = (e) => {
        e.stopPropagation();
    }
    return (
        <TagButtonContainer onClick={e => blockClick(e)}>
            <TagContain $isVisible={isVisible}>
                {tags.map((tag, index) => (
                    <Tags key={index} label={tag} onClick={() => { wordKey(tag) }} />
                ))}
            </TagContain>

            <Button onClick={toggleVisibility}>
                <FontAwesomeIcon icon={isVisible ? faAnglesUp : faAnglesDown } />
                Tags
            </Button>
        </TagButtonContainer>
    );
}

export default TagsSection;