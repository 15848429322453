
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { menuSelected } from "../reduxhandler/menuredux/menuSlice";
function RecherchePage(){
    const title = "Art"
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuSelected(title));
    }, [dispatch, title]);


    return <div>
        {/* <h1>Recherche</h1> */}
    </div>
}

export default RecherchePage;