import React from 'react';
import Logo from "../components/menuprincipal/Logo";
import Menu from "../components/menuprincipal/Menu";
import styled from 'styled-components';
import { variablesGlobals } from '../style/variables';
const NotFoundText = styled.div`
    position:absolute;
    text-align: center;
    width:100%;
    font-size: 2em;
    bottom:0;
    height:fit-content;
    color: ${variablesGlobals.veryDarkGreyBack};

`
function NotFound() {
    return (
        <>
            <NotFoundText>
                <h1>404</h1>
                <p>Page En Construction</p>
            </NotFoundText>
        </>
    );
}

export default NotFound;
