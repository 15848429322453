import styled from "styled-components";
import { variablesGlobals } from "../../../style/variables";




const TagsComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: ${variablesGlobals.darkGreyBack};
    margin: 2px 1px;
    color: #ffffff;
    width:fit-content;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.9em;
    font-weight: 400;
    transition: 0.5s;
    cursor: pointer;
    
    @media (min-width: 200px) and (max-width: 1200px) {
       font-size: 0.5em;
        
    }
    &:hover{
        background-color: white;
        color: ${variablesGlobals.darkGreyBack};
        transition: 0.5s;
    }
`;
function Tags({ label, onClick, search}){

    return <TagsComponent onMouseDown ={() => onClick(label)} key={label}>{label}</TagsComponent>;
  };
  
  export default Tags;