import React, { useState } from "react";
import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";

const ButtonStyled = styled.button`
    min-width: fit-content;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    background-color: ${variablesGlobals.veryLightGreyBack};
    font-size: 0.65em;
    border: none;
    text-align: left;
    font-weight: 700;
    margin: 5px auto;
    color: ${variablesGlobals.veryDarkGreyBack};
    transition: filter 0.5s ease-in-out;
    cursor: pointer;

    &:hover {
        filter: brightness(120%);
    }
`;

const Tooltip = styled.div`
    position: fixed;
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 200;
    width: 300px;
    font-size: 0.7em;
    margin: 45px 0 0 -150px;
    transition: left 0.1s ease-in-out, top 0.1s ease-in-out;
    display: ${({ $visible }) => ($visible ? "block" : "none")};
    left: ${({ x }) => `${x}px`};
    top: ${({ y }) => `${y}px`};
    @media (min-width: 200px) and (max-width: 1200px) {
        font-size:0.6em;
        width:150px;
    }
`;

function InfosBulle({ tooltipText, children }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipOffset, setTooltipOffset] = useState({ x: 0, y: 0 });

    const handleMouseOver = (e) => {
        setTooltipVisible(true);
        setTooltipOffset({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
        setTooltipOffset({ x: e.clientX, y: e.clientY });
    };

    const handleMouseOut = () => {
        setTooltipVisible(false);
    };

    const handleTouchStart = (e) => {
        setTooltipVisible(true);
        const touch = e.touches[0];
        setTooltipOffset({ x: touch.clientX, y: touch.clientY });
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        setTooltipOffset({ x: touch.clientX, y: touch.clientY });
    };

    const handleTouchEnd = () => {
        setTooltipVisible(false);
    };

    return (
        <>
            <ButtonStyled
                onMouseOver={handleMouseOver}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {children}
            </ButtonStyled>

            <Tooltip $visible={tooltipVisible} x={tooltipOffset.x} y={tooltipOffset.y}>
                {tooltipText}
            </Tooltip>
        </>
    );
}

export default InfosBulle;
