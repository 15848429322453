import * as THREE from 'three';

export function addMouseOverRotation(mesh, camera, scene, renderer, onClickCallback) {
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  let isMouseOver = false;

  function rotateObject() {
    if (isMouseOver) {
      mesh.rotation.y += 0.02;
      mesh.rotation.x += 0.02;

      renderer.render(scene, camera);
    }
    requestAnimationFrame(rotateObject);
  }

  function onMouseMove(event) {
    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components
    mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;

    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(mesh, true);

    isMouseOver = intersects.length > 0;
  }

  function onMouseClick(event) {
    // Update the raycaster for the click event
    mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObject(mesh, true);

    // If there's an intersection, call the onClickCallback
    if (intersects.length > 0) {
      onClickCallback();
    }
  }

  window.addEventListener('mousemove', onMouseMove, false);
  window.addEventListener('click', onMouseClick, false);

  rotateObject();

  return () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('click', onMouseClick);
  };
}
