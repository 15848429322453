import { configureStore, combineReducers  } from '@reduxjs/toolkit';
import modalReducer from '../reduxhandler/modalredux/modalSlice';
import tagsReducer from '../reduxhandler/tagsredux/tagsSlice';
import menuReducer from '../reduxhandler/menuredux/menuSlice';
import object3dReducer from '../reduxhandler/object3dredux/object3dSlice';
import paginationReducer from '../reduxhandler/object3dredux/paginationSlice';

const rootReducer = combineReducers({
    menuReducer,
    modalReducer,
    object3dReducer,
    paginationReducer,
    tagsReducer

})
const store = configureStore({
    reducer: rootReducer
});


export default store;
