
import styled from "styled-components"
import { variablesGlobals } from "../../style/variables";


const ContainFoot = styled.div`
position:absolute;
bottom:0;
width:fit-content;
height:fit-content;
color: ${variablesGlobals.darkGreyBack};
font-size: 0.5em;
`;
function Footer(){


    return(<>
        <ContainFoot><p>©Linkleen, 2024</p></ContainFoot>
    
    
    
    </>)
}

export default Footer;