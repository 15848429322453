import { useEffect, useContext, useRef } from 'react';
import * as THREE from 'three';
import sky from '../../assets/sky_intro.jpg';
import GetSceneContext from './SceneContext.jsx';

function SphericEnvironment() {
    const { sceneContext } = useContext(GetSceneContext);
    const sphereRadius = 100;
    const sphereMeshRef = useRef(null);
    const textureRef = useRef(null);  // Ref for texture to dispose it on cleanup

    useEffect(() => {
        const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
        const loader = new THREE.TextureLoader();

        loader.load(sky, texture => {
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            textureRef.current = texture;  // Assign the texture to the ref

            const sphereMaterial = new THREE.MeshBasicMaterial({
                map: texture,
                side: THREE.BackSide
            });
            sphereMeshRef.current = new THREE.Mesh(sphereGeometry, sphereMaterial);
            sceneContext.add(sphereMeshRef.current);
        });

        const animate = () => {
            if (sphereMeshRef.current && sphereMeshRef.current.material.map) {
                sphereMeshRef.current.material.map.offset.x += 0.00003;
            }
            requestAnimationFrame(animate);
        };
        animate();

        return () => {
            if (sphereMeshRef.current) {
                sceneContext.remove(sphereMeshRef.current);
                sphereMeshRef.current.geometry.dispose();
                sphereMeshRef.current.material.dispose();
            }
            // Dispose of the texture if it has been loaded
            if (textureRef.current) {
                textureRef.current.dispose();
            }
        };
    }, [sceneContext]);

    return null;
}

export default SphericEnvironment;