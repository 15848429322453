import Styled from "styled-components";
import { variablesGlobals } from "../../style/variables";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { menuSelected } from "../../reduxhandler/menuredux/menuSlice";

const LogoStyle = Styled(Link)`
    position:fixed;
    top:30%;
    left:48%;
    transform: translate(-50%, -50%);
    font-family: ${variablesGlobals.fontFamilyLogo};
    color: ${variablesGlobals.darkGreyBack};
    width:100px;
    height:100px;
    z-index: 20;
    text-decoration: none;
    transition: 0.5s;
    @media (min-width: 200px) and (max-width: 1200px) {
        left:70px;
    }
    &:hover{
        color: white;
        transition: 0.5s;
    }
    `

const LogoLink = Styled.span`
    font-size: 1em;
    font-weight: 400;
    
    `
const LogoLeen = Styled.span`
    margin:auto;
    font-size: 6em;
    font-weight: 400;
    line-height: 0.8em;
    @media (min-width: 200px) and (max-width: 1200px) {
        font-size: 4em;
    }
    `

const LogoCom = Styled.span`
    font-size: 1em;
    font-weight: 400;
    `

function Logo(){
    const dispatch= useDispatch();
    const handleClickMenu = (elementMenu) => {
        dispatch(menuSelected(elementMenu));
    }

    return(
        <>
            <LogoStyle to="/a_propos" onClick={(()=> handleClickMenu("About"))}>
                <LogoLink>Link </LogoLink>
                <LogoLeen>Leen</LogoLeen>
                <LogoCom>.com</LogoCom>
            </LogoStyle>
        </>
    )
}


export default Logo;