import ContainerAbout from "../components/aboutcomponents/ContainerAbout";
import { useState } from "react";
import Parcours from "../components/aboutDetailComponent/Parcours";
import Specialite from "../components/aboutDetailComponent/Specialite";
import Contact from "../components/aboutDetailComponent/Contact";
import Resume from "../components/aboutDetailComponent/Resume";
import styled from "styled-components";
import { variablesGlobals } from "../style/variables";

const Wrappers = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 800px;
  bottom: 0;
  margin: auto;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  user-select: none;
  @media (min-width: 1200px) {
    &::-webkit-scrollbar {
      width: 20px;
    }
    &::-webkit-scrollbar-track {
      background: #323d4213;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${variablesGlobals.lightGreyBack};
      border-radius: 15px;
      border: none;
    }
    /* Firefox */
    scrollbar-width: auto;
    scrollbar-color: #98a4bbca;
  }
`;

const ButtonContainer = styled.div`
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 13px 40px 5px 40px;
  border-radius: 0px 0px 50px 50px;
  width: fit-content;
  z-index: 1000;
  margin: 5px auto;
  position: fixed;
  @media (min-width: 200px) and (max-width: 1200px) {
    transform: translateX(-50%);
    left: 90%;
    padding: 15px;
    border-radius: 15px;
  }
  & > button {
    border: none;
    border-radius: 25px;
    z-index: 9000;
    margin: 1px 5px;
    font-size: 1em;
    padding: 5px 10px;
    transition: filter 0.2s ease-in-out;
    cursor: pointer;
    background-color: ${variablesGlobals.darkGreyBack};
    color: white;
    width: fit-content;
    font-family: ${variablesGlobals.fontFamilyLogo};
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.6em;
      margin-right: 50px;
      box-sizing: border-box;
      width: 120px;
    }

    &:hover {
      filter: brightness(1.5);
    }

    &.active {
      background-color: ${variablesGlobals.lightGreyBack};
      filter: brightness(1.5);
      color: ${variablesGlobals.veryDarkGreyBack};
      border: 2px solid ${variablesGlobals.veryDarkGreyBack};
    }
  }
`;





function AboutPage() {
  const [isActiveComponent, setIsActiveComponent] = useState("propos");

  const showPropos = () => {
    setIsActiveComponent("propos");
  };

  const showParcours = () => {
    setIsActiveComponent("parcours");
  };

  const showSpecialite = () => {
    setIsActiveComponent("specialite");
  };

  const showContact = () => {
    setIsActiveComponent("contact");
  };

  const showCv = () => {
    setIsActiveComponent("cv");
  };

  return (
    <Wrappers>
      <ButtonContainer>
        <button
          onClick={showPropos}
          className={isActiveComponent === "propos" ? "active" : ""}
        >
          À propos du site
        </button>
        <button
          onClick={showContact}
          className={isActiveComponent === "contact" ? "active" : ""}
        >
          Contact
        </button>
        <button
          onClick={showParcours}
          className={isActiveComponent === "parcours" ? "active" : ""}
        >
          Parcours
        </button>
      
        <button
          onClick={showCv}
          className={isActiveComponent === "cv" ? "active" : ""}
        >
          CV
        </button>
      </ButtonContainer>

      {isActiveComponent === "propos" && <Resume />}
      {isActiveComponent === "parcours" && <Parcours />}
      {isActiveComponent === "specialite" && <Specialite />}
      {isActiveComponent === "contact" && <Contact />}
      {isActiveComponent === "cv" && <ContainerAbout />}

      {isActiveComponent === null && <Resume />}
    </Wrappers>
  );
}

export default AboutPage;
