import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tagsList: []
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setTags: (state, action) => {
            state.tagsList = action.payload;
        }
    }
});

export const { setTags } = tagsSlice.actions;

export default tagsSlice.reducer;
