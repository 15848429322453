import { createSlice } from '@reduxjs/toolkit';
import { shuffleArray, sortArrayByName, sortArrayByDate } from '../../outils/sortingFunctions';
import { filterListByKeywords } from '../../outils/filterListByKeyword';

const initialState = {
    object3dlist: [],
    originalObject3dlist: [],
    sortedBy: null,
    filteredList: [],
    lastKeywords: [],
};

const object3dSlice = createSlice({
    name: 'object3D',
    initialState,
    reducers: {
        object3dListAction: (state, action) => {
            if (!arraysEqual(state.originalObject3dlist, action.payload)) {
                state.originalObject3dlist = action.payload;
                state.object3dlist = action.payload;
                state.filteredList = action.payload;
            }
        },
        sortByDate: (state) => {
            if (state.sortedBy !== 'date') {
                const sortedByDate = sortArrayByDate([...state.filteredList]);
                state.object3dlist = sortedByDate;
                state.filteredList = sortedByDate;
                state.sortedBy = 'date';
            }
        },
        sortByName: (state) => {
            if (state.sortedBy !== 'name') {
                const sortedByName = sortArrayByName([...state.filteredList]);
                state.object3dlist = sortedByName;
                state.filteredList = sortedByName;
                state.sortedBy = 'name';
            }
        },
        sortRandomly: (state) => {
            if (state.sortedBy !== 'random') {
                const shuffledList = shuffleArray([...state.filteredList]);
                state.object3dlist = shuffledList;
                state.filteredList = shuffledList;
                state.sortedBy = 'random';
            }
        },
        filterByWordkey: (state, action) => {
            const { keywords, selectedTags } = action.payload;
            const newKeywords = keywords.map(kw => kw.toLowerCase());
            const newTags = selectedTags.map(tag => tag.toLowerCase());
        
            if (!arraysEqual(state.lastKeywords, newKeywords) || !arraysEqual(state.lastTags, newTags)) {
                const filtered = filterListByKeywords(state.originalObject3dlist, newKeywords, newTags);
                state.filteredList = filtered;
                state.lastKeywords = newKeywords;
                state.lastTags = newTags;
            } else {
                // Consider resetting filteredList to originalObject3dlist if tags/keywords are cleared
                state.filteredList = state.originalObject3dlist;
            }
        },
        
        // Add a reset action in your Redux slice
        resetState: (state) => {
            state.filteredList = [];
            state.sortedBy = null;
            state.lastKeywords = [];
            state.lastTags = []; // Add this if you're tracking tags as well
        }

    }
});

function arraysEqual(a, b) {
    return a?.length === b?.length && a.every((val, index) => val === b[index]);
}

export const { object3dListAction, sortByDate, sortByName, sortRandomly, filterByWordkey, resetState } = object3dSlice.actions;
export default object3dSlice.reducer;
